import React, { FC } from 'react';
import '../styles/App.scss';

interface ButtonProps {
    onClick?: () => void;
}

const Button: FC<ButtonProps> = (
    {
        onClick,
        children
    }) => {
    return (
        <div className='btn_block' onClick={onClick}>
            <a className='btn to-form'>{children}</a>
        </div>
    );
};

export default Button;
