import React, { FC, useState } from 'react';
import '../styles/App.scss';
import { IListItem } from '../shared/models/list-item';

interface ListItemsProps {
    items: IListItem[];
}

const ListItems: FC<ListItemsProps> = (
    {
        items
    }) => {

    const [shown, setShown] = useState(false);

    return (
        <div className='list-wrapper'>
            {items.map((listItem: IListItem, listIdx: number) => (
                <div className='list__item' key={listIdx}>
                    <div className='list__item-title'>
                        <span>{listItem.name}</span>
                        <span className={shown ? 'all' : ''} onClick={() => setShown(!shown)}>
                            {listItem.country.join(' | ')}
                        </span>
                    </div>
                    <div className='list__item-content'>
                        <span>{listItem.content.join(' | ')}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ListItems;
