import React, { ChangeEvent, useState } from 'react';
import { IFeedback } from '../shared/models/feedback';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sendFeedback } from '../shared/api/feedback-api';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import translate from '../i18n/translate';


const Form = () => {
    let isBot = false;
    const [isLoading, setLoading] = useState(false);
    const intl = useIntl();
    const schema = yup.object().shape({
        email: yup.string()
            .required('Обязательное поле для заполнения')
            .email(),
        name: yup.string()
            .required('Обязательное поле для заполнения')
            .min(2, 'Минимальная длина - 2 символа')
            .max(256, 'Превышено максимальное количество символов'),
        phoneNumber: yup.string().required('Обязательное поле для заполнения')
            .min(4, 'Минимальная длина - 4 символа')
            .max(30, 'Превышено максимальное количество символов'),
        message: yup.string()
            .required('Обязательное поле для заполнения')
            .min(2, 'Минимальная длина - 2 символа'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<IFeedback>({
        resolver: yupResolver(schema),
    });

    const onSubmit = (feedback: IFeedback) => {
        if (isBot) {
            return;
        }
        setLoading(true);
        sendFeedback(feedback)
            .then(
                (data) => {
                    if (data.data.status === 'SUCCESS') {
                        reset();
                        toast.success(<div>Ваше обращение успешно отправлено.<br/>
                                           В ближайшее время мы с вами свяжемся!</div>);
                    }
                },
                (error) => {
                    console.log(error);
                    toast.error(<div>Извините, что-то пошло не так.<br/>
                                     Попробуйте снова, либо обратитесь позже</div>);
                },
            )
            .finally(() => setLoading(false));
    };

    const catchBot = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            isBot = true;
        }
    };

    return (
        <section className='form'>
            <div className='container'>
                <div className='cover_outside'>
                    <div className='text_block'>
                        <p>{translate('thanksTo')}<b>{translate('knowAboutClient')}</b>{translate('automateProcesses')}</p>
                    </div>
                    <div className='form_block'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ul>
                                <li>
                                    <input type='text' name='theme' className='form-theme'
                                        placeholder='theme' onChange={(e) => catchBot(e)}/>
                                </li>
                                <li>
                                    <label htmlFor=''>{translate('fullName')}</label>
                                    <input  type='text' placeholder='Ваше Имя' disabled={isLoading}
                                        {...register('name')}
                                        required/>
                                    <span className='form__text form__text_error-message'>{errors.name?.message}</span>
                                </li>
                                <li>
                                    <label htmlFor=''>{translate('email')}</label>
                                    <input type='email' placeholder='mail@example.com' disabled={isLoading}
                                        {...register('email')}
                                        required/>
                                    <span className='form__text form__text_error-message'>{errors.email?.message}</span>
                                </li>
                                <li>
                                    <label htmlFor=''>{translate('phoneNumber')}</label>
                                    <input type='text' disabled={isLoading}
                                        {...register('phoneNumber')}
                                        required/>
                                    <span className='form__text form__text_error-message'>{errors.phoneNumber?.message}</span>
                                </li>
                                <li>
                                    <label htmlFor=''>{translate('message')}</label>
                                    <textarea disabled={isLoading}
                                        {...register('message')}
                                        required/>
                                    <span className='form__text form__text_error-message'>{errors.message?.message}</span>
                                </li>
                                <li>
                                    <input type='submit' value={intl.formatMessage({ id: 'send' })} disabled={isLoading}/>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Form;
