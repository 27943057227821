import React, { useState } from 'react';
import ListItems from './ListItems';
import { ISuppliers } from '../shared/models/suppliers';
import { suppliers } from '../data';
import '../styles/Accordion.scss';
import arrow from '../assets/icons/arrow.svg';

const Accordion = () => {

    const [selected, setSelected] = useState<number | null>(0);

    const toggle = (idx: number) => {
        if (selected === idx) {
            return setSelected(null);
        }

        setSelected(idx);
    };

    return (
        <ul className='nav-list'>
            {suppliers.map((item: ISuppliers, idx: number) => (
                <li className={selected === idx ? 'nav-item active' : 'nav-item'} key={idx}>
                    <div className='section-title' onClick={() => toggle(idx)}>
                        <span>
                            {item.title}
                            <img src={arrow} alt='arrow' />
                        </span>
                    </div>
                    <div className='section-content'>
                        <ListItems items={item.content}/>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default Accordion;
