import { LOCALES } from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        'headerTitle': 'One integration - all sources!',
        'headerSubtitle': 'Launch projects and comfortably interact with a large number of data providers',
        'how': 'How to find out everything about a client?',
        'strongProductSides': '1. Learn the strengths of your product',
        'formatsAgree': '2. Agree on formats and sign an agreement',
        'implementSystem': '3. Connect to the service',
        'connectSources': '4. Choose your data sources',
        'startProject': '5. Run the project',
        'arrangeSupport': '6. Arrange support',
        'firstStep': 'All you need is to take the first step.',
        'takingObligations': ' We will then take care of everything else ',
        'whatIs': 'What is ',
        'axiLink': 'AxiLink',
        'axiLinkDescMain': `AxiLink is a software product for automated data collection, processing and deduplication
         about a potential client of a financial institution from various sources (anti-fraud services,
          open databases, etc.).`,
        'axiLinkDesc': `AxiLink is not just a proprietary solution that provides a one-stop shop for interacting with data providers.
                        Our tool optimizes your IT resources costs and allows you to fully automate the underwriting and verification processes in lending and insurance issuing.`,
        'manageRequests': 'Anyone can process requests and analyze data - only AxiLink can do it quickly and efficiently.',
        'fn_collecting': 'Data collection in synchronous/asynchronous mode',
        'fn_support': 'Support for secure data transmission channels (GOST, TLS, etc.)',
        'fn_transformation': 'Data conversion into a single intersystem format',
        'fn_depersonalization': 'Depersonalization of data',
        'fn_cashing': 'Query caching',
        'fn_parameterization': 'Parameterization of request settings number of attempts, waiting time, determination of the source status terminal non-terminal',
        'fn_storageAndLogging': 'Data storage and logging',
        'noChaos': 'No chaos. We organize the process to fit',
        'withTuning': ' your business needs. ',
        'single': 'Single',
        'reqFormat': ' request format',
        'readyIntegration': 'Ready integrations',
        'analyticalSystems': ' with industrial analytical systems (FICO, Experian SAS, Drools)',
        'support': 'Support',
        'majorProtocols': ' for major protocols and interaction modes',
        'viewPossibility': 'Ability to visualize',
        'crmData': ' data in your CRM',
        'whoIs': 'Who is ',
        'solutionFor': 'this solution for?',
        'clients': `All business projects that need an affordable solution to acquire data from different sources on their customers.
                    Our tool can be used by clients varying from banks and microfinance organizations, to insurance companies and online platforms.`,
        'ourSolution': 'Our solution is your comprehensive development',
        'it': 'IT.',
        'businessFocus': `Free up resources and focus on your[ business. When working with a large number of products and data providers,
                          bringing in a simple and effective integration solution will optimize your business operations. Our team is always ready to set
                          up channels to integrate with information systems, conduct further testing, and prepare the project to launch.`,
        'riskTechnologies': 'Risk Technologies.',
        'automatedChecks': `Do you constantly fight against fraud and work with a large amount of data, wishing to automate
                            the collection and processing of requests? We are able to quickly solve it. AxiLink performs automated checks as part
                            of the processing of loan applications, and helps to form a full portrait of the customer.`,
        'business': 'Business.',
        'fastStartGuarantee': `Looking to start selling goods or services on your online platform with payments by instalments?
                               With AxiLink you can launch your project within a month.`,
        'why': 'Why ',
        'questionMark': '?',
        'scalability': 'Scalability',
        'requestProcessing': 'Processing more than 10 million requests monthly.',
        'versatility': 'Versatility',
        'allInfoSystems': 'Interaction with any information systems and adaptability to any data formats.',
        'speed': 'Speed',
        'integrationSpeed': 'On average, integration with any external data source through our services takes one week.',
        'saving': 'Saving',
        'costReduction': '80% lower integration support costs, 30% lower caching costs, 20% faster request  processing.',
        'security': 'Safety',
        'integrationSecurity': 'All service are carried out on behalf of the Customer, we do not have access to personal or sensitive data.',
        'fineTuning': 'Fine installation',
        'requestManagement': 'Manage parallel and serial requests, timeouts and number of attempts.',
        'dateStore': 'As a result of the provided services, you will receive a full-fledged data warehouse and tools made ready to work with straight away.',
        'externalDataProvidersList': 'List of external data providers with out-of-the-box integration',
        'month': 'month',
        'connectionCost': 'Connection cost',
        'connectionCostInd':'Calculated individually',
        'connectionAndService': 'Connection cost and Service',
        'connectionAndServiceCostInd': 'Calculated individually',
        'service': 'Service',
        'orderDemo': 'order a demo',
        'getDemo': 'get demo',
        'send': 'Send',
        'product': 'Product',
        'companyName': 'Axiomatika',
        'tariffs': 'Tariffs',
        'company': 'Company',
        'jobs': 'Jobs',
        'contacts': 'Contacts',
        'integrations': 'Integrations',
        'developers': 'Developers',
        'axiomatika': 'axiomatika',
        'about': 'About',
        'thanksTo': 'Thanks to AxiLink, ',
        'knowAboutClient': 'knowing everything about your customers is easy.',
        'automateProcesses': ' Automate evaluation and engagement processes - grow your business at an accelerated pace!',
        'fullName': 'Full Name',
        'email': 'Email',
        'phoneNumber': 'Phone number',
        'message': 'Message',
        'documentation': 'documentation',
        'usersGuide': 'User Guide',
        'installationInstructions': 'Installation instructions',
        'softwareAdditionalInteraction': 'Software additional interaction',
        'lifeCycleProcessesDescription': 'Life cycle processes description',
        'supportService': 'Technical support service',
        'supportPhoneNumber': 'Phone number'
    }
};
