import React from 'react';
import logo from '../assets/icons/logo.png';

const Header = () => {
    return (
        <header>
            <div className='container'>
                <a className='logo'>
                    <img src={logo} alt='logo'/>
                </a>
            </div>
        </header>
    );
};

export default Header;
