import React, { useState } from 'react';

import './styles/App.scss';
import 'react-toastify/dist/ReactToastify.min.css';

import FrontPage from './components/FrontPage';
import { ToastContainer } from 'react-toastify';
import { I18nProvider, LOCALES } from './i18n';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function App() {
    const [currentLocale, setCurrentLocale] = useState(LOCALES.RUSSIAN);
    const languageChange = (value: string) => {
        setCurrentLocale(value);
    };

    return (
        <I18nProvider locale={currentLocale}>
            <div className='wrapper'>
                <BrowserRouter>
                    <Routes>
                        <Route path={'/:lang'} element={<FrontPage currentLocale={currentLocale} languageChange={languageChange}/>}/>
                        <Route path='*' element={<Navigate to='/ru' replace />} />
                    </Routes>
                </BrowserRouter>
                <ToastContainer
                    position='top-center'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover/>
            </div>
        </I18nProvider>
    );
}

export default App;
