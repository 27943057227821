import { ISuppliers } from './shared/models/suppliers';
import { IListItem } from './shared/models/list-item';

export const suppliers: ISuppliers[] = [
    {
        title: 'Данные кредитных бюро',
        content: [
            {
                name: 'НБКИ',
                country: ['РФ'],
                content: ['Кредитная история', 'Скоринг бюро']
            },
            {
                name: 'НБКИ Открытые источники',
                country: ['РФ'],
                content: ['Данные по телефонным номерам']
            },
            {
                name: 'НБКИ Ficov3',
                country: ['РФ'],
                content: ['Скоринг']
            },
            {
                name: 'НБКИ AFS',
                country: ['РФ'],
                content: ['Предотвращение мошенничества']
            },
            {
                name: 'Equifax',
                country: ['РФ'],
                content: ['Кредитная история', 'Скоринг бюро']
            },
            {
                name: 'Equifax FPS',
                country: ['РФ'],
                content: ['Предотвращение мошенничества']
            },
            {
                name: 'Equifax eID',
                country: ['РФ'],
                content: ['Идентификация клиентов', 'Предотвращение мошенничества']
            },
            {
                name: 'ОКБ',
                country: ['РФ'],
                content: ['Кредитная история', 'Скоринг бюро']
            },
            {
                name: 'IDV от ОКБ',
                country: ['РФ'],
                content: ['Предотвращение мошенничества']
            },
            {
                name: 'National Hunter от ОКБ',
                country: ['РФ'],
                content: ['Предотвращение мошенничества']
            },
            {
                name: 'Русский Стандарт',
                country: ['РФ'],
                content: ['Кредитная история']
            },
            {
                name: 'БКИ Польша',
                country: ['Польша'],
                content: ['Кредитная история']
            },
            {
                name: 'CRIF Польша',
                country: ['Польша', 'Филиппины'],
                content: ['Скоринг']
            },
            {
                name: 'KRD',
                country: ['Польша'],
                content: ['Система Бюро экономической информации по заемщику']
            },
            {
                name: 'CIBI',
                country: ['Филиппины'],
                content: ['Кредитное бюро']
            },
            {
                name: 'SIVABON',
                country: ['Румыния'],
                content: ['Кредитное бюро']
            }
        ],
    },
    {
        title: 'Внешние платные базы',
        content: [
            {
                name: 'Кронос (МБКИ)',
                country: ['РФ'],
                content: ['Анализ благонадежности физлица']
            },
            {
                name: 'Double Data',
                country: ['РФ'],
                content: ['Атрибуты социального профиля анализ текстов, интересов заемщика предотвращение мошенничества']
            },
            {
                name: 'CreditRegistry',
                country: ['РФ'],
                content: ['Объединение данных БКИ в едином формате, дедупликация']
            },
        ],
    },
    {
        title: 'Социальные сети, Медиа',
        content: [
            {
                name: 'Mail.ru',
                country: ['РФ'],
                content: ['Оценка заемщика по профилю, скоринг']
            },
            {
                name: 'SocioHub',
                country: ['РФ'],
                content: ['Анализ данных соцсетей']
            },
        ] as IListItem[],
    },
    {
        title: 'Скоринг по номеру телефона',
        content: [
            {
                name: 'Telesign',
                country: ['ОАЭ'],
                content: ['Оценка заемщиков на основе анализа телефонных номеров']
            },
            {
                name: 'TrustingSocial',
                country: ['Вьетнам'],
                content: ['Анализ данных об использовании мобильных устройств операторами мобильной связи']
            }
        ],
    },
    {
        title: 'Мобильные операторы',
        content: [
            {
                name: 'Билайн',
                country: ['РФ'],
                content: ['Скоринг бюро, аналитика пользования номера, фио']
            },
            {
                name: 'МТС',
                country: ['РФ'],
                content: ['Скоринг бюро, аналитика пользования номера, фио']
            },
            {
                name: 'Мегафон',
                country: ['РФ'],
                content: ['Скоринг бюро, аналитика пользования номера, фио']
            }
        ],
    },
    {
        title: 'Государственные сервисы (открытые источники)',
        content: [
            {
                name: 'ФССП',
                country: ['РФ'],
                content: ['Наличие либо отсутствие задолженности, арестованное имущество']
            },
            {
                name: 'ФМС',
                country: ['РФ'],
                content: ['Действительность документа по данным ФМС']
            },
            {
                name: 'Федеральная служба по финансовому мониторингу (Росфинмониторинг)',
                country: ['РФ'],
                content: ['Перечень организаций и физических лиц, в отношении которых имеются сведения об их причастности к экстремистской деятельности или терроризму']
            },
            {
                name: 'ФНС',
                country: ['РФ'],
                content: ['Выписки ЮГРЮЛ и ЕГРИП Сведения: ликвидация, банкротство, недобросовестность']
            },
            {
                name: 'Госналог',
                country: ['РФ'],
                content: ['Сведения о задолженности по налогам']
            },
            {
                name: 'Единый федеральный реестр сведений о банкротстве (ЕФРСБ)',
                country: ['РФ'],
                content: ['Сведения о банкротстве физических и юридических лиц']
            },
            {
                name: 'ZIGNSEC',
                country: ['Румыния'],
                content: ['Сведения о нормативных проверках физлица, принадлежность к терроризму, объектам финмониторинга и AML']
            },
            {
                name: 'PESEL&RDO',
                country: ['Польша'],
                content: ['Веб-служба проверки данных пользователя (PESEL, номер ID-карты) в государственных базах данных']
            }
        ],
    },
    {
        title: 'Платежные системы',
        content: [
            {
                name: 'QIWI',
                country: ['РФ'],
                content: [
                    'Скоринг',
                    'Время жизни номера',
                    'Количество платежей',
                    'География платежей',
                    'Суммы платежей за период',
                    'Даты платежей',
                    'Интересы (на основе поведения в интернете)'
                ]
            },
            {
                name: 'CyberPlat +',
                country: ['РФ'],
                content: ['Оценка заемщика']
            },
            {
                name: 'Яндекс.Касса',
                country: ['РФ'],
                content: [
                    'Выдача займов на банковские карты, кошельки Яндекс.Денег, мобильные и банковские счета.',
                    'Погашение займов с помощью банковских карт, кошельков Яндекс.Денег, кошельков Webmoney, наличных (в 170000 точках по всей России), счетов мобильных и интернет-банков Сбербанка, Альфа-Банка и Промсвязьбанка, счетов мобильных телефонов.'
                ]
            },
            {
                name: 'MandarinPay',
                country: ['РФ'],
                content: [
                    'Прием платежей и выплат клиентам',
                    'Прием денежных средств с использованием различных платежных каналов',
                    'Возможность привязки банковской карты с последующим автоматическим списанием средств',
                    'Выплаты Вашим клиентам с использованием различных платежных каналов (банковские счета и карты, электронные деньги, системы денежных переводов, счета мобильных телефонов и т.д.)'
                ]
            },
        ],
    },
    {
        title: 'Сервисы по анализу данных',
        content: [
            {
                name: 'Финкарта f-karta.ru',
                country: ['РФ'],
                content: ['Предотвращение мошенничества. Позволяет в режиме реального времени проверять историю предоставления займов и попыток погашения займов на указанные при подаче заявки пластиковые карты']
            },
            {
                name: 'CleverData',
                country: ['РФ'],
                content: ['Независимая технологическая платформа, объединяющая поставщиков и потребителей данных для обмена анонимными знаниями о своей аудитории.\n' +
                'Сервис позволяет потребителям самостоятельно выбрать нужные данные на Бирже поставщика и получить результаты сделки в реальном времени по отдельному профилю пользователя.']
            },
            {
                name: 'JuicyScore',
                country: ['РФ', 'Вьетнам', 'ОАЭ', 'Саудовская Аравия', 'Филиппины', 'Шри-Ланка'],
                content: ['Анти-фрод инструмент для финансового сектора на основании неперсональных данных']
            },
            {
                name: 'Unicom24',
                country: ['РФ'],
                content: ['Скоринг, кредитный отчет']
            },
            {
                name: 'CashOff',
                country: ['РФ'],
                content: ['Облачный сервис, собирающий финансовую информацию из разных источников']
            },
            {
                name: 'CIAN (ЦИАН)',
                country: ['РФ'],
                content: ['Анализ объектов недвижимости']
            },
            {
                name: 'Casebook',
                country: ['РФ'],
                content: ['Оценка факторов риска и исковой нагрузки потенциальных контрагентов;\n' +
                'автоматизация работы юристов и правовых департаментов.']
            },
            {
                name: 'KALAPA',
                country: ['Вьетнам'],
                content: [
                    'Информация бюро (Credit Bureau info)',
                    'Информация о медицинской страховке (Medical Insurance data)',
                    'Информация о семье (Family Member info)',
                    'Информация о работе (CareerStatus)',
                    'Информация о налоге (Personal tax)'
                ]
            },
            {
                name: 'DotConnect',
                country: ['Вьетнам'],
                content: ['Сервис по оценке заемщиков']
            },
            {
                name: 'Instantor',
                country: ['Вьетнам'],
                content: ['Сервис по оценке заемщиков']
            },
            {
                name: 'Sociohub sociohub.ru',
                country: ['РФ'],
                content: ['Индетификация пользователей; получение данных из соц.сетей: Facebook, Вконтакте, Одноклассники, Мой мир, Twitter']
            },
            {
                name: 'Трансдекра',
                country: ['РФ'],
                content: ['Расшифровка параметров ТС, стоимость, регистрация']
            },
            {
                name: 'Audavin',
                country: ['РФ'],
                content: ['Кол-во расчётов, сумма ремонта']
            },
            {
                name: 'Kontomatik',
                country: ['Польша', 'Филиппинам'],
                content: ['Источник данных «Знай своего клиента» (KYC) и оценки финансового поведения заемщиков']
            },
            {
                name: 'Ekata',
                country: ['ОАЭ'],
                content: ['Проверка идентификационных данных (электронная почта, телефон, имя (физическое или юридическое лицо), физический адрес и IP), в режиме реального времени']
            },
            {
                name: 'SEON',
                country: ['РФ', 'Польша', 'Вьетнам', 'ОАЭ', 'Саудовская Аравия', 'Бахрейн', 'Кувейт'],
                content: ['Предотвращение мошенничества']
            },
            {
                name: 'MFM Solutions mfms.ru(MobileScoring)',
                country: ['РФ'],
                content: ['Сервис по предоставлению данных анализа входящих смс отправленных на абонентский номер заявителя финансовыми компаниями']
            },
            {
                name: 'Iovation',
                country: ['Вьетнам'],
                content: ['Анти-фрод инструмент для финансового сектора']
            },
            {
                name: 'Автокод',
                country: ['РФ'],
                content: ['Отчет с достоверной информацией о состоянии авто из надежных источников: факты коммерческого использования, участие в ДТП, наличие страховых случаев, количество владельцев автомобиля, нахождение в розыске, владение юридическим лицом. О нахождении транспортного средства в залоге у банка и о сервисном обслуживании автомобиля']
            },
            {
                name: 'EmailListVerify',
                country: ['Шри-Ланка'],
                content: ['Система безопасной и эффективной проверки списка адресов электронной почты и создания подробных отчетов']
            },
            {
                name: 'InfoBip',
                country: ['Шри-Ланка'],
                content: ['Сервис для оценки']
            },
            {
                name: 'FinScore',
                country: ['Филиппины'],
                content: ['Кредитный скоринг для оценки заемщиков']
            },
            {
                name: 'Контур-Фокус',
                country: ['РФ'],
                content: ['Проверка данных по юрлицам и ИП']
            },
        ],
    }
];
