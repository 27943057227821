import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { LOCALES } from './locales';
import messages from './messages/index';
interface ProviderProps {
    children: any;
    locale: string
}
const Provider = ({ children, locale = LOCALES.RUSSIAN}: ProviderProps) => (
    <IntlProvider
        locale={locale}
        textComponent={Fragment}
        messages={messages[locale]}
    >
        { children }
    </IntlProvider>
);

export default Provider;
